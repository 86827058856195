import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation } from 'react-router-dom';
import './App.css';
import { Home, User, Menu, Search, MessageCircle } from 'lucide-react';

const HomeScreen = lazy(() => import('./Screens/HomeScreen'));
const MapScreen = lazy(() => import('./Screens/MapScreen'));
const ProfileScreen = lazy(() => import('./Screens/ProfileScreen'));
const BusinessScreen = lazy(() => import('./Screens/PlaceDetailsScreen'));
const AuthScreen = lazy(() => import('./Screens/AuthScreen'));
const ExplorePage = lazy(() => import('./Screens/ExploreScreen'));
const NavScreen = lazy(() => import('./Screens/NavScreen'));
const InboxScreen = lazy(() => import('./Screens/InboxScreen'));
const FullScreenVideoFeed = lazy(() => import('./Screens/FullScreenVideoFeed'));
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const showNavBar = location.pathname !== '/auth';

  return (
    <div className="App flex flex-col md:flex-row h-screen">
      {showNavBar && (
        <nav className="hidden md:block w-48 bg-black text-white h-screen fixed left-0 top-0">
          <ul className="flex flex-col h-full justify-center space-y-8">
            <li>
              <NavLink to="/" className={({ isActive }) => `flex items-center space-x-4 px-6 py-2 ${isActive ? "active" : ""}`}>
                <Home className="w-6 h-6" />
                <span>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/map" className={({ isActive }) => `flex items-center space-x-4 px-6 py-2 ${isActive ? "active" : ""}`}>
                <Search className="w-6 h-6" />
                <span>Map</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/inbox" className={({ isActive }) => `flex items-center space-x-4 px-6 py-2 ${isActive ? "active" : ""}`}>
                <MessageCircle className="w-6 h-6" />
                <span>Inbox</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile" className={({ isActive }) => `flex items-center space-x-4 px-6 py-2 ${isActive ? "active" : ""}`}>
                <User className="w-6 h-6" />
                <span>Profile</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/nav" className={({ isActive }) => `flex items-center space-x-4 px-6 py-2 ${isActive ? "active" : ""}`}>
                <Menu className="w-6 h-6" />
                <span>Nav</span>
              </NavLink>
            </li>
           
          </ul>
        </nav>
      )}
      <div className={`content flex-grow flex flex-col ${showNavBar ? 'md:ml-48' : ''}`}>
        <Suspense fallback={<div className="flex items-center justify-center h-screen">Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/map" element={<MapScreen />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/business" element={<BusinessScreen />} />
            <Route path="/auth" element={<AuthScreen />} />
            <Route path="/explore" element={<ExplorePage />} />
            <Route path="/nav" element={<NavScreen />} />
            <Route path="/inbox" element={<InboxScreen />} />
            <Route path="/fullscreen" element={<FullScreenVideoFeed />} />
          </Routes>
        </Suspense>
      </div>
      {/* {showNavBar && (
        <nav className="md:hidden lg:hidden fixed bottom-0 left-0 right-0 bg-black text-white z-50">
          <ul className="flex justify-around">
            <li>
              <NavLink to="/" className={({ isActive }) => isActive ? "active" : ""}>
                <Home className="w-6 h-6 mx-auto" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/map" className={({ isActive }) => isActive ? "active" : ""}>
                <Search className="w-6 h-6 mx-auto" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/inbox" className={({ isActive }) => isActive ? "active" : ""}>
                <MessageCircle className="w-6 h-6 mx-auto" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/profile" className={({ isActive }) => isActive ? "active" : ""}>
                <User className="w-6 h-6 mx-auto" />
              </NavLink>
            </li>
            <li>
              <NavLink to="/nav" className={({ isActive }) => isActive ? "active" : ""}>
                <Menu className="w-6 h-6 mx-auto" />
              </NavLink>
            </li>
          </ul>
        </nav>
      )} */}
    </div>
  );
}

export default App;